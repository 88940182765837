@tailwind base;
@tailwind components;
@tailwind utilities;

.popup-content {
  width: 90%;
  padding: 0;
  max-height: 90%;
  overflow: scroll;
  @apply border border-gray-400 rounded;
}

/* article content styles */
.article-content .bold {
  @apply font-bold;
}

.article-content .pageTitle {
  @apply mb-4 text-2xl font-bold;
}

/* .italic {
  @apply italic;
} */

.article-content p {
  @apply mb-4 leading-7;
}

.article-content a {
  @apply text-blue-700;
}

.article-content ul {
  @apply pl-4 mb-4 list-disc list-outside;
}

.article-content img.img-center {
  @apply block mx-auto;
}

.article-content img.img-desktop-right-mobile-center {
  @apply float-right clear-right m-4 mr-0;
}

.article-content img.img-desktop-right-300-mobile-right {
  @apply float-right clear-right m-4 mr-0;
  width: 300px;
}

.article-content img.img-desktop-right-300-mobile-center {
  @apply block mx-auto;
  width: 300px;
}

.article-content img.img-desktop-right-200-mobile-right {
  @apply float-right clear-right m-4 mr-0;
  width: 200px;
}

.article-content .subhead14 {
  @apply text-lg;
}

.article-content .subhead18 {
  @apply text-xl;
}
.article-content .head {
  @apply text-2xl;
}

.article-content .footer {
  @apply mb-1 text-xs;
}

@media (min-width: 768px) {
  .article-content img.img-desktop-right-300-mobile-center {
    @apply float-right clear-right m-4 mr-0;
  }
}

.article-content h1 {
  @apply text-2xl font-bold;
}
.article-content h2 {
  @apply text-xl font-bold;
}

/* svg styles */
.afb {
  fill: #204988;
}
.bfb {
  fill: #fff;
}

.atw {
  fill: #2bbef3;
}
.btw {
  fill: #fff;
}
.aig {
  fill: #235e82;
}
.big {
  fill: #fff;
}
.aps {
  fill: #bc061b;
}
.bps {
  fill: #fff;
}
.aprb {
  fill: #00742e;
}
.bprb,
.cprb {
  fill: #fff;
}
.bprb {
  fill-rule: evenodd;
}
.w-34-p {
  width: 34px;
}

@media (max-width: 768px) {
  .social-links-icon {
    border-radius: 13px;
  }
}

.my-account-menu:before,
.my-account-menu:after {
  bottom: 100%;
  left: 20%;
  border: 1px solid;
  border-width: 1px;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  box-sizing: border-box;
}

.my-account-menu:before {
  border-color: rgba(233, 232, 232, 0);
  border-bottom-color: #0062ac;
  top: -12px;
  border-width: 11px;
  margin-left: -19px;
}
.my-account-menu:after {
  border-color: rgba(245, 246, 244, 0);
  border-bottom-color: #f4f4f4;
  border-width: 10px;
  margin-left: -18px;
  top: -8px;
}

/* zendesk style */
iframe#launcher {
  display: none;
}

/* header styles */
.mobile-logo {
  width: 60vw;
}

@media (max-width: 400px) {
  .header-info {
    margin-left: 0.5rem !important;
  }
}

@media (max-width: 340px) {
  .header-info {
    margin-left: 0.2rem !important;
  }
}

.prevent-scroll {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
}

.html-scroll {
  height: 100%;
  overflow: hidden;
}

.checkout-button {
  width: 108px;
  height: 35px;
}
.user-links {
  line-height: 17px;
}
.navbar li:first-child a{
  padding-left: 0px;
}
.cart-img {
  width: 34px;
}
.search-input {
  font-size: 14px;
}
.search-input::-webkit-input-placeholder {
  color:black!important;
}
.search-input::-moz-placeholder {
  color:black!important;
}
.search-input::-ms-placeholder {
  color:black!important;
}
.search-input::placeholder {
  color:black!important;
}
.fs-12 {
  font-size: 12px;
}
.fs-13 {
  font-size: 13px;
}
.fs-15 {
  font-size: 15px;
}

@media (min-width: 1024px){
  .header-container {
    padding-top: 1rem;
    padding-bottom: 11px;
  }
  .search-btn {
    width: 300px;
    height: 35px;
  }
}

/* footer styles */
.bg-footer-bg {
  padding-top: 30px;
}

/* left nav*/
.left-nav {
  line-height: 20px;
}
.left-nav-category {
  font-size: 16px;
}
